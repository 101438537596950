import { useAppContext } from "contexts";
import { useMemo } from "react";
import { IWGameItem } from "types/games";

export default function useGetWGames() {
  const { isFeatureEnabled } = useAppContext();
  const WGames = useMemo<IWGameItem[]>(() => {
    return [
      {
        title: "plinko",
        imageSrc: "/images/gamesComponent/plinko.png",
        link: "/games/plinko",
        disabled: false,
      },
      {
        title: "mines",
        imageSrc: "/images/gamesComponent/mines.png",
        link: "/games/mines",
        disabled: false,
      },
      {
        title: "roulette",
        imageSrc: "/images/gamesComponent/roulette.png",
        link: "/games/roulette",
        disabled: false,
      },
      {
        title: "blackjack",
        imageSrc: "/images/gamesComponent/blackjack.png",
        link: "/games/blackjack",
        disabled: false,
      },
      {
        title: "crash",
        imageSrc: "/images/gamesComponent/crash.png",
        link: "/games/crash",
        disabled: isFeatureEnabled("ALLOW_CRASH_GAMES") === false,
      },
      {
        title: "coinflip",
        imageSrc: "/images/gamesComponent/coinflip.png",
        link: "/games/coinflip",
        disabled: false,
      },
    ];
  }, [isFeatureEnabled]);

  return { WGames };
}
