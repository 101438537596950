import Link from "next/link";
import React from "react";
import styled from "styled-components";

export interface IMenuComponent {
  id?: string;
}

interface IMenuComponentItems {
  name?: string;
  href: string;
  background?: string;
}

const menuItems: IMenuComponentItems[] = [
  {
    name: "Leaderboards",
    href: "/leaderboards",
    background: "/images/MenuComponent/leaderboards-menu-item.png",
  },
  {
    name: "All bonuses",
    href: "/all-bonuses",
    background: "/images/MenuComponent/bonuses-menu-item.png",
  },
  {
    name: "WGames",
    href: "/games",
    background: "/images/MenuComponent/games-menu-item.png",
  },
  {
    name: "Raffles",
    href: "/raffles",
    background: "/images/MenuComponent/raffles-menu-item.png",
  },
  {
    name: "Points Store",
    href: "/points-shop",
    background: "/images/MenuComponent/points-store-menu-item.png",
  },
  {
    name: "Calendar",
    href: "/advent-calendar",
    background: "/images/MenuComponent/calendar-menu-item.png",
  },
  {
    name: "Pachinko",
    href: "/pachinko",
    background: "/images/MenuComponent/pachinko-menu-item.png",
  },
];

export const MenuComponent: React.FC<IMenuComponent> = ({ id }): JSX.Element => {
  return (
    <>
      <Box id={id} className="container">
        <MenuList>
          {menuItems.map((item, index) => (
            <MenuItem key={index} href={item.href} background={item.background}>
              <MenuItemTitle>{item.name}</MenuItemTitle>
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    </>
  );
};

const Box = styled.div`
  padding: 26px 16px;
`;

const MenuList = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;

  /* @media (max-width: 939px) {
    flex-wrap: wrap;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: stretch;
  } */
  
  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 570px) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
`;

const MenuItem = styled(Link)<IMenuComponentItems>`
  overflow: hidden;
  position: relative;
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  border-radius: 12px;
  height: 156px;
  will-change: width, height;
  transition: all 0.3s;

  min-height: 300px;

  &:after {
    content: "";
    z-index: 0;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.background}) no-repeat center top / cover;
    transform: scale(1);
    will-change: transform;
    transition: transform 0.3s;
  }

  @media (min-width: 1921px)  {
    width: 320px;
    flex: 1 1 320px;
    height: 220px;

    &:nth-child(-n + 3) {
      width: 420px;
      flex: 1 1 420px;
    }
  }

  @media (min-width: 940px) and (max-width: 1920px) {
    width: 300px;
    flex: 1 1 250px;
    height: 200px;

    &:nth-child(-n + 3) {
      width: calc(100% / 3);
      flex: 1 1 340px;
    }
  }

  @media (max-width: 939px) {
    min-width: 220px;
    height: 160px;
    flex: 1 1 220px;

    &:nth-child(-n + 3) {
      min-width: 220px;
      height: 160px;
      flex: 1 1 220px;
    }
  }

  &:hover {
    &:after {
      transform: scale(1.1);
    }
  }
`;

const MenuItemTitle = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  height: 40px;
  background: rgba(29, 34, 52, 0.5);
  backdrop-filter: blur(20.020492553710938px);
  width: 100%;
  height: 40px;
  padding: 0 16px;
  color: var(--daylight-color);
  font-family: var(--font-family-golos);
  font-size: 16px;

  @media (max-width: 767px) {
    height: 35px;
    font-size: 14px;
  }
`;
