import styled from "styled-components";

export type AccessRestrictedModalProps = {
  title: string;
  text: string;
};

const AccessRestrictedModal: React.FC<AccessRestrictedModalProps> = ({ title, text }) => {
  return (
    <ModalContainer>
      <ModalTitle>{title}</ModalTitle>
      <ModalDescription>{text}</ModalDescription>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  border-radius: 12px;
  border: 1px solid var(--daylight-daylight-5, rgba(200, 215, 245, 0.05));
  background: #181c29;
  padding: 35px 38px 35px 39px;
  gap: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ModalTitle = styled.div`
  color: var(--daylight-daylight-main, #d5e0f5);
  text-align: center;
  font-family: Exo;
  font-size: 13px;
  font-style: italic;
  font-weight: 800;
  line-height: 130%; /* 26px */
  text-transform: uppercase;
`;

const ModalDescription = styled.div`
  color: var(--daylight-daylight-75, rgba(200, 215, 245, 0.75));
  text-align: center;

  font-family: "Golos Text";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export default AccessRestrictedModal;
