import AccessRestrictedModal from "components/ScreenComponent/StartEarningRewards/AccessRestrictedModal";
import React, { PropsWithChildren } from "react";
import styled from "styled-components";

interface RestictedComponentWrapperProps extends PropsWithChildren {
  informationBlock?: React.ReactElement;
  isBlurred: boolean;
  text: string;
  title: string;
}
export const RestictedComponentWrapper = ({
  children,
  informationBlock,
  isBlurred,
  text,
  title,
}: RestictedComponentWrapperProps) => {
  return (
    <BlurredContainer>
      {isBlurred && (
        <AccessRestrictedModalContainer>
          <AccessRestrictedModal title={title} text={text} />
        </AccessRestrictedModalContainer>
      )}
      <BlurredWrapper data-blur-active={isBlurred}>{children}</BlurredWrapper>
    </BlurredContainer>
  );
};

const BlurredWrapper = styled.div`
  &[data-blur-active="true"] {
    filter: blur(10px);
    pointer-events: none;
  }
`;

const BlurredContainer = styled.div`
  position: relative;
`;

const AccessRestrictedModalContainer = styled.div`
  z-index: 3;
  position: absolute;
  left: 50%;
  min-width: 350px;
  max-width: 500px;
  top: 50%;
  transform: translate(-50%, -50%);
`;
