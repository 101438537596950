import React from "react";
import styled from "styled-components";
import Link from "next/link";
import Image from "next/image";
import { IWGameItem } from "types/games";
import useGetWGames from "hooks/games/useGetWGames";

export interface IWGamesComponent {
  id?: string;
}

const colors = {
  coinflip: "#5DAC01",
  crash: "#DB01FF",
  blackjack: "#FFC701",
  roulette: "#25FF01",
  mines: "#FF2F01",
  plinko: "#0171AC",
};

export const WGamesItemCard = ({ link, imageSrc, title, disabled }: IWGameItem) => {
  // @ts-ignore
  const specifiedColor = colors?.[title];
  return (
    <WGameCardContainer>
      {disabled && (
        <ComingBanner>
          <Image
            alt="lock"
            src="/images/games/lock.svg"
            width={14}
            height={14}
            style={{ marginRight: 8 }}
          />
          <span>coming soon</span>
        </ComingBanner>
      )}
      <WGameCard href={link} img={imageSrc} fcolor={specifiedColor} data-disabled={disabled}>
        <WGameTitle>
          {title}
          <span>w Originals</span>
        </WGameTitle>
      </WGameCard>
    </WGameCardContainer>
  );
};

export const WGamesOriginItems = () => {
  const { WGames } = useGetWGames();
  return (
    <>
      {WGames.map((item, index) => {
        return (
          <WGamesItemCard
            key={index}
            link={item.link}
            imageSrc={item.imageSrc}
            title={item.title}
            disabled={item.disabled}
          />
        );
      })}
    </>
  );
};

// export const WGamesComponent: React.FC<IWGamesComponent> = ({ id }): JSX.Element => {
//   return (
//     <>
//       <Box id={id} className="container">
//         <SectionWGamesGrid>
//           {WgamesList.map((item, index) => {
//             return (
//               <WGameCard key={index} href={item.link} img={item.imageSrc}>
//                 <WGameTitle>
//                   {item.title}
//                   <span>w Originals</span>
//                 </WGameTitle>
//               </WGameCard>
//             );
//           })}
//         </SectionWGamesGrid>
//       </Box>
//     </>
//   );
// };

const Box = styled.div`
  padding: 16px;
`;

export const ComingBanner = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-transform: uppercase;
  color: #e9f4ff;
  position: absolute;
  background: #1316204d;
  border-radius: 4px;
  z-index: 2;
  padding: 14px 12px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

// const SectionWGamesGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(168px, 1fr));
//   place-content: center;
//   gap: 16px;

//   @media (max-width: 939px) {
//     grid-template-columns: repeat(6, 1fr);
//   }

//   @media (max-width: 767px) {
//     grid-template-columns: repeat(4, 1fr);
//   }

//   @media (max-width: 670px) {
//     grid-template-columns: repeat(3, 1fr);
//   }

//   @media (max-width: 500px) {
//     grid-template-columns: repeat(2, 1fr);
//   }
// `;

const WGameTitle = styled.div`
  display: flex;
  flex-direction: column;
  color: #ffffff;
  text-align: center;
  font-family: var(--font-family-exo);
  font-size: 22px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0.3px;
  opacity: 0.9;
  text-transform: uppercase;
  gap: 10px;
  transition: gap 0.3s, font-size 0.3s;

  span {
    font-size: 10px;
    opacity: 0.75;
  }

  @media (max-width: 939px) {
    font-size: 20px;
    gap: 5px;
  }
`;
const WGameCardContainer = styled.div`
  position: relative;
`;
const WGameCard = styled(Link)<{ img: string; fcolor: string }>`
  overflow: hidden;
  display: flex;
  align-items: end;
  justify-content: center;
  flex: 1;
  position: relative;
  border-radius: 12px;
  padding: 16px;
  transition: padding 0.3s, box-shadow 0.2s;
  aspect-ratio: 3/4;
  /* color: attr(data-color); */

  &[data-disabled="true"] {
    filter: blur(3px);
    pointer-events: none;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(${(props) => props.img}) no-repeat center top / cover;
    transform: scale(1);
    transition: transform 0.3s;
  }
  box-shadow: 0px 0px 0px 0px ${(props) => props.fcolor};

  &:hover {
    padding: 10px 16px;
    box-shadow: 0px 0px 16px 2px ${(props) => props.fcolor};

    &:before {
      transform: scale(1.05);
    }

    ${WGameTitle} {
      gap: 0;
      font-size: 26px;

      @media (max-width: 939px) {
        font-size: 22px;
      }
    }
  }
`;
